/*
Template Name: custom - custom Investment HTML Template
Description: custom - custom HTML Template.
Version: 1.0.0
*/

/*Preloader Starts*/
@keyframes "preloader-wrapper" {
	0% {
		opacity: 1;
	}
	50% {
		opacity: .5;
	}
	100% {
		opacity: 1;
	}
}


html {
	color: #222;
	font-size: 1em;
	line-height: 1.4;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	font-size: 18px;
	scroll-behavior: smooth;
}
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}
audio {
	vertical-align: middle;
}
canvas {
	vertical-align: middle;
}
iframe {
	vertical-align: middle;
}
img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%;
}
svg {
	vertical-align: middle;
}
video {
	vertical-align: middle;
}
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
	padding: 0.35em 0.75em 0.625em;
}
textarea {
	resize: vertical;
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	overflow: auto;
	width: 100%;
	background-color: #fff;
	border: 1px solid #37055c;
	outline: none;
	height: 185px;
	resize: none;
	padding: 10px;
}
.browserupgrade {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}
.hidden {
	display: none !important;
}

.visuallyhidden.focusable {
	&:active {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
		white-space: inherit;
	}
	&:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
		white-space: inherit;
	}
}
.invisible {
	visibility: hidden;
}
.clearfix {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
body {
	margin: 0;
	padding: 0;
	margin: 0;
	font-size: 15px;
	color: #222;
	line-height: 24px;
	overflow-x: hidden;
	font-family: 'Poppins', sans-serif;
	* {
		&::-webkit-scrollbar {
			width: 4px;
			background-color: #F5F5F5;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #000000;
		}
		&::-webkit-scrollbar-track {
			-webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
			background-color: #F5F5F5;
		}
	}
}
main {
	display: block;
}
h1 {
	font-size: 2em;
	margin: 0.67em 0;
	font-weight: 600;
	margin: 0;
	line-height: 1.3;
	color: #37055c;
	font-family: 'Poppins', sans-serif;
	font-size: 48px;
	a {
		color: #37055c;
		&:hover {
			color: #e35f46;
		}
	}
}
pre {
	font-family: monospace, monospace;
	font-size: 1em;
}
a {
	background-color: transparent;
	display: inline-block;
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	transition: all ease 0.3s;
	outline: none;
	color: #37055c;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
}
abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}
b {
	font-weight: 500;
}
strong {
	font-weight: 500;
}
code {
	font-family: monospace, monospace;
	font-size: 1em;
}
kbd {
	font-family: monospace, monospace;
	font-size: 1em;
}
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}
small {
	font-size: 80%;
}
sub {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	bottom: -0.25em;
}
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	top: -0.5em;
}
button {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	overflow: visible;
	text-transform: none;
	-webkit-appearance: button;
	color: #ffffff;
	text-transform: uppercase;
	line-height: 43px;
	border: 1px solid transparent;
	background: rgb(85,14,137);
	background: linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	padding: 0 30px;
	display: inline-block;
	font-weight: 500;
	font-size: 16px;
	-moz-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	-webkit-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	width: 100%;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	outline: none;
	height: 50px;
	color: #222;
	cursor: pointer;
	&:hover {
		background: #fff;
		color: rgb(85,14,137) !important;
		border: 1px solid rgb(85,14,137);
	}
	&::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}
	&:-moz-focusring {
		outline: 1px dotted ButtonText;
	}
}
.information-request-section .form-group input::placeholder,
.information-request-section .form-group textarea::placeholder {
	color: #222;
    opacity: 1;
}

.form-control::placeholder {
	color: #222;
    opacity: 1;
}
input, .form-control {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	overflow: visible;
	width: 100%;
	background-color: #fff;
	border: 1px solid #37055c;
	outline: none;
	height: 48px;
	color: #222;
	font-weight:400;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	padding-left: 10px;
	&:focus {
		border: 1px solid #37055c;
		box-shadow: 0 0 0 .2rem rgba(55,5,92,.15);
	}
}
input[type="checkbox"], input[type="radio"]{
	width: auto;
	height: auto;
}
optgroup {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}
select {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	text-transform: none;
	width: 100%;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	outline: none;
	height: 50px;
	color: #222;
	cursor: pointer;
}
[type="button"] {
	-webkit-appearance: button;
	color: #ffffff;
	text-transform: uppercase;
	line-height: 43px;
	border: 1px solid transparent;
	background: rgb(85,14,137);
	background: linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	padding: 0 30px;
	display: inline-block;
	font-weight: 500;
	font-size: 16px;
	-moz-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	-webkit-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	&:hover {
		background: #fff;
		color: rgb(85,14,137) !important;
		border: 1px solid rgb(85,14,137);
	}
	&::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}
	&:-moz-focusring {
		outline: 1px dotted ButtonText;
	}
}
[type="reset"] {
	-webkit-appearance: button;
	color: #ffffff;
	text-transform: uppercase;
	line-height: 43px;
	border: 1px solid transparent;
	background: rgb(85,14,137);
	background: linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	padding: 0 30px;
	display: inline-block;
	font-weight: 500;
	font-size: 16px;
	-moz-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	-webkit-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	&:hover {
		background: #fff;
		color: rgb(85,14,137) !important;
		border: 1px solid rgb(85,14,137);
	}
	&::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}
	&:-moz-focusring {
		outline: 1px dotted ButtonText;
	}
}
[type="submit"] {
	-webkit-appearance: button;
	color: #ffffff;
	text-transform: uppercase;
	line-height: 43px;
	border: 1px solid transparent;
	background: rgb(85,14,137);
	background: linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	padding: 0 30px;
	display: inline-block;
	font-weight: 600;
	font-size: 18px;
	-moz-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	-webkit-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	&:hover {
		background: #fff;
		color: rgb(85,14,137) !important;
		border: 1px solid rgb(85,14,137);
	}
	&:focus {
		background: #fff;
		color: rgb(85,14,137) !important;
		border: 1px solid rgb(85,14,137);
		box-shadow: 0 0 0 .2rem rgba(55,5,92,.2);
	}
	&:active {
		background: #fff;
		color: rgb(255,255,255) !important;
		border: 1px solid rgb(85,14,137);
		box-shadow: 0 0 0 .2rem rgba(55,5,92,.2);
	}
	&::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}
	&:-moz-focusring {
		outline: 1px dotted ButtonText;
	}
}
legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}
progress {
	vertical-align: baseline;
}
[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}
[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}
[type="number"] {
	&::-webkit-inner-spin-button {
		height: auto;
	}
	&::-webkit-outer-spin-button {
		height: auto;
	}
}
[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
	&::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}
details {
	display: block;
}
summary {
	display: list-item;
}
template {
	display: none;
}
[hidden] {
	display: none;
}
h2 {
	font-weight: 600;
	margin: 0;
	line-height: 1.3;
	color: #37055c;
	font-family: 'Poppins', sans-serif;
	font-size: 40px;
	
	a {
		color: #37055c;
		&:hover {
			color: #e35f46;
		}
	}
}
h3 {
	font-weight: 600;
	margin: 0;
	line-height: 1.3;
	color: #37055c;
	font-family: 'Poppins', sans-serif;
	font-size: 28px;
	
	a {
		color: #37055c;
		&:hover {
			color: #e35f46;
		}
	}
}
h4 {
	font-weight: 600;
	margin: 0;
	line-height: 1.3;
	color: #37055c;
	font-family: 'Poppins', sans-serif;
	font-size: 24px;
	
	a {
		color: #37055c;
		&:hover {
			color: #e35f46;
		}
	}
}
h5 {
	font-weight: 600;
	margin: 0;
	line-height: 1.3;
	color: #37055c;
	font-family: 'Poppins', sans-serif;
	font-size: 20px;
	
	font-weight: 500;
	a {
		color: #37055c;
		&:hover {
			color: #e35f46;
		}
	}
}
h6 {
	font-weight: 600;
	margin: 0;
	line-height: 1.3;
	color: #37055c;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: 500;
	a {
		color: #37055c;
		&:hover {
			color: #e35f46;
		}
	}
}
.hero-section {
	h1 {
		color: #222;
	}
	position: relative;
	padding: 170px 0 330px;
	&:after{
		
		content:"";
	}
}
.inner-page{
	.hero-section{
		padding: 40px 0 260px;
	}
}

ul {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
		padding: 5px 0;
	}
}
label {
	cursor: pointer;
}
input[type="submit"] {
	cursor: pointer;
	background-color: #0a3d62;
	color: #ffffff;
	font-weight: 700;
	padding: 0;
}
.bg_img {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.footer-section {
	&::before {
		position: absolute;
		content: '';
		bottom: 0;
		right: 0;
		left: 0;
		top: 130px;
	}
	position: relative;
	background: #f3f3f3;
	padding-bottom: 40px;
}

.hover-tab-menu {
	.tab-menu {
		li {
			&:nth-child(1) {
				.menu-thumb {
					&:after {
						position: absolute;
						content: '';
						bottom: 65%;
						right: 65%;
						background: #ffffff;
						width: 50px;
						height: 50px;
						z-index: -1;
					}
					position: relative;
				}
			}
			&:nth-child(3) {
				.menu-thumb {
					&:after {
						position: absolute;
						content: '';
						top: 65%;
						right: 65%;
						background: #ffffff;
						width: 50px;
						height: 50px;
						z-index: -1;
					}
					position: relative;
				}
			}
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 0;
			margin-bottom: 97px;
			cursor: grab;
			.menu-thumb {
				padding: 5px;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
				border: 1px solid #e1d8ec;
				width: 65px;
				height: 65px;
				span {
					line-height: 53px;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;
					text-align: center;
					font-size: 32px;
					background-color: #eae7f8;
					width: 53px;
					display: inline-block;
					color: #33406a;
					font-weight: 500;
				}
			}
			.menu-content {
				width: calc(100% - 65px);
				padding-left: 40px;
				.title {
					text-transform: uppercase;
					margin-bottom: 15px;
				}
			}
			&:nth-child(2) {
				-webkit-transform: translateX(95px);
				-ms-transform: translateX(95px);
				transform: translateX(95px);
			}
			&:last-child {
				margin: 3px;
			}
		}
		position: relative;
		z-index: 1;
		li.active {
			.menu-thumb {
				span {
					background: #37055c;
					color: #ffffff;
					animation-name: fadeIn;
					-webkit-animation-name: fadeIn;
					-moz-animation-name: fadeIn;
					animation-duration: .5s;
					-webkit-animation-duration: .5s;
					-moz-animation-duration: .5s;
				}
			}
		}
	}
	&::after {
		position: absolute;
		content: '';
		width: 490px;
		height: 510px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		top: -22px;
		border: 1px dashed #37055c;
		right: calc(75% + 23px);
	}
	&::before {
		position: absolute;
		content: '';
		width: 490px;
		height: 512px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		top: -22px;
		right: 78%;
		border: 9px solid #eae7f8;
	}
	position: relative;
	overflow: hidden;
}
.hover-tab-area {
	.tab-area {
		.tab-item {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 9;
			img {
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				position: absolute;
				content: "";
				max-height: 300px;
				max-width: 300px;
				z-index: 99;
				-webkit-transition: all ease 0.3s;
				-moz-transition: all ease 0.3s;
				transition: all ease 0.3s;
			}
		}
		&::after {
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			position: absolute;
			content: "";
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			background-color: rgba(187, 166, 212, 0.4);
			width: 340px;
			height: 340px;
		}
		&::before {
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			position: absolute;
			content: "";
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			background-color: rgba(187, 166, 212, 0.4);
			width: 410px;
			height: 410px;
		}
		width: 470px;
		height: 470px;
		position: relative;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background-color: rgba(187, 166, 212, 0.1);
		overflow: hidden;
		* {
			position: relative;
			z-index: 1;
		}
		.tab-item.active {
			img {
				visibility: visible;
				opacity: 1;
			}
		}
		.tab-item.first {
			animation-name: fadeInUp;
			-webkit-animation-name: fadeInUp;
			-moz-animation-name: fadeInUp;
			animation-duration: 1s;
			-webkit-animation-duration: 1s;
			-moz-animation-duration: 1s;
		}
		.tab-item.second {
			animation-name: fadeInLeft;
			-webkit-animation-name: fadeInLeft;
			-moz-animation-name: fadeInLeft;
			animation-duration: 1s;
			-webkit-animation-duration: 1s;
			-moz-animation-duration: 1s;
		}
		.tab-item.third {
			animation-name: fadeInDown;
			-webkit-animation-name: fadeInDown;
			-moz-animation-name: fadeInDown;
			animation-duration: 1s;
			-webkit-animation-duration: 1s;
			-moz-animation-duration: 1s;
		}
	}
}

.preloader {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	background: #fff;
	z-index: 999;
	align-items: center;
	justify-content: center;
}
.footer-top {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1;
	justify-content: space-between;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	align-items: center;
	.links {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		li {
			padding: 0 15px;
			a {
				font-size: 15px;
				color: #222;
				&:hover {
					color: #37055c;
				}
			}
		}
	}
	>* {
		padding-bottom: 20px;
		margin-bottom: 0;
	}
	.logo {
		max-width: 210px;
		padding: 0;
		a {
			display: block;
		}
		img {
			max-width: 100%;
		}
	}
}
.footer-bottom-area {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1;
	justify-content: space-between;
	flex-wrap: wrap-reverse;
}
.social-icons {
	display: flex;
	flex-wrap: wrap;
	margin: -7.5px;
	li {
		padding: 7.5px;
		a {
			width: 36px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			border: 1px solid rgba(255, 255, 255, 0.1);
			color: #ffffff;
			font-size: 14px;
		}
		a.active {
			background: -webkit-linear-gradient(-103deg, #ef774c 0%, #ed684f 35%, #e84351 76%, #e73351 100%);
			border-color: #f55c25;
			box-shadow: 0px 10px 10px 0px rgba(132, 141, 156, 0.3);
		}
	}
}
.header-top {
	ul {
		display: flex;
		flex-wrap: wrap;
		li {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-size: 14px;
			color: #526288;
			padding: 0 5px;
			.nice-select {
				background-color: transparent;
			}
			i {
				margin-right: 7px;
			}
			a {
				color: #fff;
			}
			.select-bar {
				padding: 0;
				padding-right: 15px;
				border: none;
				height: 28px;
				.current {
					line-height: 28px;
					display: block;
				}
				&::after {
					right: 0;
				}
				.list {
					right: 0;
					left: auto;
					.option {
						line-height: 30px;
						min-height: 30px;
					}
				}
			}
		}
	}
	padding: 7px 0;
	background: #37055c;
	z-index: 99;
	position: relative;
	ul.support-area {
		li {
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	ul.cart-area {
		justify-content: flex-end;
		li {
			margin-left: 15px;
			&:first-child {
				margin-left: 0;
			}
		}
		.list {
			.option {
				margin: 0;
			}
		}
	}
}
.header-bottom {
	.header-area {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: 5px 0;
		.menu {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		.logo {
			a {
				display: block;
				img {
					max-width: 62%;
				}
			}
		}
	}
	z-index: 99;
	width: 100%;
}
.menu-item-has-children {
	>a {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		&::after {
			font: normal normal normal 18px/1 FontAwesome;
			content: "\f107";
			margin-left: 5px;
			margin-right: 10px;
		}
	}
	position: relative;
}
.banner-content {
	.button-group {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.custom-button {
			margin-right: 50px;
		}
	}
	.title {
		font-size: 70px;
		line-height: 80px;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: -5px;
		margin-bottom: 10px;
		span {
			font-weight: 700;
		}
	}
	p {
		margin: 0;
		font-size: 26px;
		line-height: 36px;
		margin-bottom: 32px;
	}
}
.newslater-area {
	display: flex;
	flex-wrap: wrap;
	background-color: #ffffff;
	border-radius: 20px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	display: block;
	.newslater-content {
		width: 50%;
		max-width: 445px;
		padding-left: 40px;
		padding-right: 40px;
		.cate {
			font-weight: 500;
			font-size: 20px;
			text-transform: uppercase;
			color: #e35f46;
			display: block;
			margin-bottom: 23px;
			margin-top: -7px;
		}
		.title {
			text-transform: uppercase;
			margin-bottom: 30px;
		}
		.newslater-form {
			input {
				height: 50px;
				padding: 0;
				background-color: transparent;
				border-radius: 0;
				border: 1px solid transparent;
				font-size: 16px;
				border-bottom-color: #b7b7b7;
				margin-bottom: 30px;
				&:focus {
					padding-left: 15px;
					border: 1px solid #e35f46;
				}
			}
			button {
				color: #ffffff;
				text-transform: uppercase;
				line-height: 43px;
				border: 1px solid transparent;
				background: rgb(85,14,137);
				background: linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
				background: -webkit-linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
				padding: 0 30px;
				display: inline-block;
				font-weight: 500;
				font-size: 16px;
				-moz-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
				-webkit-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
				box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
				&:hover {
					background: #fff;
					color: rgb(85,14,137) !important;
					border: 1px solid rgb(85,14,137);
				}
			}
		}
	}
	.newslater-thumb {
		width: 50%;
		text-align: right;
		position: relative;
		img {
			max-width: 100%;
		}
		div[class*="coin"] {
			position: absolute;
			opacity: 0;
		}
		.coin-1 {
			top: 220px;
			right: 120px;
			animation: coin 6s linear infinite;
			-webkit-animation: coin 6s linear infinite;
			-moz-animation: coin 6s linear infinite;
			animation-delay: 1s;
			-webkit-animation-delay: 1s;
			-moz-animation-delay: 1s;
		}
		.coin-2 {
			top: 190px;
			right: 120px;
			animation: coin 6s linear infinite;
			-webkit-animation: coin 6s linear infinite;
			-moz-animation: coin 6s linear infinite;
			animation-delay: 2s;
			-webkit-animation-delay: 2s;
			-moz-animation-delay: 2s;
		}
		
	}
}

.feature-item {
	margin-bottom: 50px;
	.feature-thumb {
		display: flex;
		flex-wrap: wrap;
		width: 120px;
		height: 120px;
		align-items: center;
		justify-content: center;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		margin: 0 auto 40px;
		background: #ffffff;
		-webkit-transition: all ease 0.3s;
		-moz-transition: all ease 0.3s;
		transition: all ease 0.3s;
		box-shadow: 0 10px 20px rgba(187, 166, 212, 0.3);
		img {
			max-height: 71px;
		}
	}
	text-align: center;
	.feature-content {
		.title {
			text-transform: uppercase;
			margin-bottom: 15px;
		}
	}
	&:hover {
		.feature-thumb {
			box-shadow: 0 10px 20px rgba(187, 166, 212, 0.5);
		}
	}
}
.about-item {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 46px;
	.about-thumb {
		width: 65px;
		img {
			width: 100%;
		}
	}
	.about-content {
		padding-left: 30px;
		width: calc(100% - 65px);
		.title {
			margin-bottom: 16px;
			margin-top: 0;
		}
	}
}

.faq-tab {
	.tab-menu {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		text-align: center;
		justify-content: center;
		margin: -5px;
		margin-bottom: 30px;
		position: relative;
		z-index: 9;
		li {
			padding: 0;
			line-height: 50px;
			border-radius: 25px;
			background: -webkit-linear-gradient(-103deg, #ef774c 0%, #ed684f 35%, #e84351 76%, #e73351 100%);
			box-shadow: 0px 10px 10px 0px rgba(132, 141, 156, 0.3);
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
			color: #ffffff;
			padding: 0 38px;
			margin: 5px;
		}
		li.active {
			background-image: -moz-linear-gradient(-45deg, #9830e7 0%, #1837dd 100%);
			background-image: -webkit-linear-gradient(-45deg, #9830e7 0%, #1837dd 100%);
			background-image: -ms-linear-gradient(-45deg, #9830e7 0%, #1837dd 100%);
			box-shadow: 0px 10px 10px 0px rgba(132, 141, 156, 0.3);
		}
	}
}
.why-area {
	display: flex;
	flex-wrap: wrap;
	margin: -15px;
	margin-bottom: 25px;
}
.why-item {
	.why-inner {
		display: flex;
		flex-wrap: wrap;
		margin: 10px;
		border: 1px solid #bccaea;
		padding: 20px 30px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		background: #ffffff;
		align-items: center;
		-webkit-transition: all ease 0.3s;
		-moz-transition: all ease 0.3s;
		transition: all ease 0.3s;
		.why-thumb {
			width: 60px;
			img {
				max-width: 100%;
			}
		}
		.why-content {
			width: calc(100% - 60px);
			padding-left: 20px;
			.title {
				margin: 0;
				font-size: 18px;
				font-weight: 500;
				text-transform: capitalize;
			}
		}
		&:hover {
			border-color: transparent;
			box-shadow: 0px 5px 15px 0px rgba(23, 2, 121, 0.3);
		}
	}
	width: 100%;
}

.pagination {
	display: flex;
	flex-wrap: wrap;
	padding: 5px 10px;
	li {
		padding: 5px;
		a {
			border-radius: 50%;
			border:none;
			background-color: #f1f5f7;
			box-shadow: 0px 5px 10px 0px rgba(62, 83, 216, 0.2);
			color: #33406a;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			&:hover {
				background: -webkit-linear-gradient(-103deg, #ef774c 0%, #ed684f 35%, #e84351 76%, #e73351 100%);
				color: #ffffff;
			}
		}
		a.active {
			background: -webkit-linear-gradient(-103deg, #ef774c 0%, #ed684f 35%, #e84351 76%, #e73351 100%);
			color: #ffffff;
		}
	}
}
.check_boxes {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px 10px;
}
.banner-bg {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	div[class*="chart"] {
		position: absolute;
	}
	.chart-1 {
		top: 350px;
		right: calc(50% - 50px);
	}
	.chart-2 {
		top: 300px;
		right: calc(50% + 206px);
	}
	.chart-3 {
		top: 290px;
		right: calc(50% + 688px);
	}
	.chart-4 {
		top: 250px;
		right: calc(50% + 150px);
	}
}
.call-overlay {
	&::before {
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		position: absolute;
		content: "";
		background-color: #370161;
		width: 101%;
		height: 101%;
	}
	position: relative;
	overflow: hidden;
	.container {
		position: relative;
		z-index: 1;
	}
	.container-fluid {
		position: relative;
		z-index: 1;
	}
}
.padding-top {
	padding-top: 60px;
}
.padding-bottom {
	padding-bottom: 60px;
}
.preloader-wrapper {
	div {
		position: absolute;
		width: 15px;
		height: 15px;
		top: 0;
		border-radius: 50px;
		-moz-border-radius: 50px;
		-webkit-border-radius: 50px;
		animation: preloader-wrapper 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		box-sizing: content-box;
		&:nth-child(1) {
			transform: translate(10px, 0);
			background: #0a69aa;
			animation-delay: -0.6s;
		}
		&:nth-child(2) {
			transform: translate(30px, 0);
			background: #91bcc6;
			animation-delay: -0.4s;
		}
		&:nth-child(3) {
			transform: translate(50px, 0);
			background: #07abcc;
			animation-delay: -0.2s;
		}
		&:nth-child(4) {
			transform: translate(70px, 0);
			background: #194645;
			animation-delay: -1s;
		}
	}
	width: 100px;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
}
.mb-30-none {
	margin-bottom: -30px !important;
}
.tab-item {
	display: none;
	animation-name: fadeInUp;
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
}
.tab-item.active {
	display: block;
}
.tab-menu {
	li {
		cursor: pointer;
	}
}
.mt-10 {
	margin-top: 10px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-50 {
	margin-top: 50px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-50 {
	margin-bottom: 50px;
}
.pt-10 {
	padding-top: 10px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-50 {
	padding-top: 50px;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-20 {
	padding-bottom: 20px;
}
.pb-50 {
	padding-bottom: 50px;
}
.c-thumb {
	overflow: hidden;
	a {
		display: block;
	}
	img {
		width: 100%;
		-webkit-transition: all ease 0.3s;
		-moz-transition: all ease 0.3s;
		transition: all ease 0.3s;
	}
}
.light-color {
	* {
		color: #ffffff;
	}
}
.section-bg {
	background: #f2f5f7;
}
.section-white {
	background: #fff;
}
.body-bg {
	background: #0a1227;
}
.section-header {
	text-align: center;
	margin-bottom: 40px;
	.cate {
		font-size: 24px;
		font-weight: 500;
		text-transform: capitalize;
		color: #37055c;
		display: block;
		margin-top: -5px;
		margin-bottom: 26px;
	}
	.title {
		margin-bottom: 15px;
		text-transform: uppercase;
		span {
			color: #e35f46;
		}
	}
	p {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}
}
.section-header.left-style {
	margin-left: 0;
	p {
		margin-left: 0;
	}
	p.mw-500 {
		max-width: 550px;
	}
}
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.7);
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	transition: all ease 0.3s;
	visibility: hidden;
	opacity: 0;
}
.overlay.active {
	opacity: 1;
	visibility: visible;
}
.cl-1 {
	color: #37055c;
}
.cl-2 {
	color: #e35f46;
}
.footer-bottom {
	padding: 8px 0;
	background: #e3e3e3;
	font-size: 13px;
	p {
		margin: 0 !important;
	}
	.social-icons {
		margin-bottom: 12.5px;
	}
}
.contact-info {
	p {
		margin: 0;
	}
}
.footer-link {
	li {
		padding: 2px 0;
	}
	a {
		color: #222;
	}
}
.footer-newslater-form {
	input[type="text"] {
		background: #fff;
	}
}
.header-section {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 11;
	.logo-2 {
		display: none;
	}
}
.inner-page{
	.header-section {
		position: relative;
	}
}
.header-section.plan-header {
	.header-top {
		border-color: #5169e8;
		ul {
			li {
				.select-bar {
					&::after {
						border-color: #ffffff;
					}
					.current {
						color: #ffffff;
					}
				}
				i {
					color: #ffffff;
				}
				a {
					color: #ffffff;
				}
			}
		}
	}
	.logo {
		display: none;
	}
	.logo-2 {
		display: block;
	}
	.header-bar {
		span {
			background: #ffffff;
		}
	}
}
.header-section.active {
	position: fixed;
	top: 0;
	left: 0;
	animation: fadeInDown;
	-webkit-animation: fadeInDown;
	-moz-animation: fadeInDown;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	background: #ffffff;
	border-bottom: 1px solid rgba(51, 64, 106, 0.4);
}
.header-bar {
	position: relative;
	cursor: pointer;
	width: 25px;
	height: 20px;
	margin-left: auto;
	span {
		position: absolute;
		display: inline-block;
		height: 3px;
		width: 100%;
		-webkit-transition: all ease 0.3s;
		-moz-transition: all ease 0.3s;
		transition: all ease 0.3s;
		background-color: #33406a;
		left: 0;
		&:first-child {
			top: 0;
		}
		&:nth-child(2) {
			top: 52%;
			transform: translateY(-65%);
		}
		&:last-child {
			bottom: 0;
		}
	}
}
.header-bar.active {
	span {
		&:first-child {
			-webkit-transform: rotate(45deg) translate(3px, 9px);
			-ms-transform: rotate(45deg) translate(3px, 9px);
			transform: rotate(45deg) translate(3px, 9px);
		}
		&:nth-child(2) {
			opacity: 0;
		}
		&:last-child {
			-webkit-transform: rotate(-45deg) translate(3px, -9px);
			-ms-transform: rotate(-45deg) translate(3px, -9px);
			transform: rotate(-45deg) translate(3px, -9px);
		}
	}
}
.banner-section {
	padding: 310px 0 364px;
	position: relative;
	overflow: hidden;
}

.client-slider {
	padding: 15px;
}
.m--30 {
	margin: -30px;
}
.background-map {
	max-height: 120px;
	text-align: right;
}
.newslater-section {
	position: relative;
	z-index: 1;
}
.call-section {
	padding: 120px 0 60px;
	margin-top: -50px;
}
.call--item {
	.cate {
		font-size: 24px;
		line-height: 34px;
		color: #f55c25;
		display: block;
		margin-bottom: 24px;
	}
	.title {
		text-transform: uppercase;
		color: #ffffff;
	}
}

.hero-content {
	.title {
		font-size: 43px;
		line-height: 50px;
		margin-bottom: 12px;
	}
	padding-left: 30px;
}
.hero-content.text-white {
	.title {
		color: #ffffff;
	}
	.breadcrumb {
		li {
			color: #ffffff;
			a {
				color: #ffffff;
			}
		}
	}
}
.breadcrumb {
	background: transparent;
	padding: 0;
	font-size:14px;
	li {
		padding: 0;
		text-transform: uppercase;
		color: #ffffff;
		font-weight: 400;
		a {
			padding: 0;
			text-transform: uppercase;
			color: #ffffff;
			font-weight: 400;
			&::after {
				margin: 0 10px;
				font-weight: 500;
				font: normal normal normal 14px/1 FontAwesome;
				content: "\f105";
			}
		}
	}
}
.text-white {
	.breadcrumb {
		li {
			color: #ffffff;
			a {
				color: #ffffff;
			}
		}
	}
}
.left-bottom {
	background-position: left bottom;
}
.mission-section {
	overflow: hidden;
	position: relative;
	.mission-shape {
		position: absolute;
		left: 0;
		top: 120px;
		bottom: 0;
		img {
			height: 100%;
		}
	}
	.container {
		position: relative;
		z-index: 1;
	}
}
.mission-wrapper {
	margin: -20px 0;
}
.faq-section {
	position: relative;
	overflow: hidden;
	.container {
		position: relative;
		z-index: 1;
	}
	.container-fluid {
		position: relative;
		z-index: 1;
	}
}
.faq-item {
	padding: 30px 0 10px;
	border-bottom: 1px solid #bccaea;
	.faq-title {
		padding-bottom: 20px;
		padding-left: 60px;
		padding-right: 40px;
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			color: #4a61ff;
			width: 38px;
			height: 40px;
			background-size: contain;
		}
		.right-icon {
			display: inline-block;
			width: 40px;
			height: 40px;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			position: absolute;
			top: 0;
			right: 0;
			&::after {
				position: absolute;
				content: '';
				background: #4a61ff;
				top: 50%;
				-webkit-transition: all ease 0.3s;
				-moz-transition: all ease 0.3s;
				transition: all ease 0.3s;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				width: 20px;
				height: 2px;
			}
			&::before {
				position: absolute;
				content: '';
				background: #4a61ff;
				top: 50%;
				-webkit-transition: all ease 0.3s;
				-moz-transition: all ease 0.3s;
				transition: all ease 0.3s;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				width: 2px;
				height: 20px;
			}
		}
		.title {
			max-width: 900px;
		}
	}
	.faq-content {
		max-width: 780px;
		display: none;
		padding: 10px 0 20px;
		padding-left: 60px;
		p {
			margin-top: 0;
		}
	}
	&:first-child {
		padding-top: 0;
	}
	&:last-child {
		padding-bottom: 0;
		border: none;
	}
}
.faq-item.active {
	.faq-content {
		display: block;
	}
}
.faq-item.open {
	.faq-title {
		.right-icon {
			&::before {
				-webkit-transform: translate(-50%, -50%) rotate(90deg);
				-ms-transform: translate(-50%, -50%) rotate(90deg);
				transform: translate(-50%, -50%) rotate(90deg);
			}
		}
	}
}
.why-affiliate-content {
	.custom-button {
		&:hover {
			box-shadow: 0px 10px 10px 0px rgba(132, 141, 156, 0.3);
		}
	}
}
.why-affiliate-section {
	position: relative;
}
.why--thumb {
	position: absolute;
	bottom: 120px;
	left: calc(50% - 140px);
}
.how-item.active {
	background: -moz-linear-gradient(90deg, #3b26db 1%, #7b19cb 100%);
	background: -webkit-linear-gradient(90deg, #3b26db 1%, #7b19cb 100%);
	background: -ms-linear-gradient(90deg, #3b26db 1%, #7b19cb 100%);
	box-shadow: 0px 10px 15px 0px rgba(67, 36, 217, 0.5);
	.how-content {
		.title {
			color: #ffffff;
		}
		a {
			color: #23d605;
		}
	}
}
.how-section {
	position: relative;
	.ball-3 {
		top: 0;
		bottom: auto;
	}
	.ball-2 {
		top: 0;
		bottom: auto;
	}
}
.contact-form {
	padding: 53px 30px 60px;
	background: #ffffff;
	border-radius: 30px;
	.form-group {
		margin-bottom: 24px;
		&:last-child {
			margin-bottom: 0;
		}
		label {
			font-size: 22px;
			font-weight: 500;
			margin-bottom: 4px;
			color: #33406a;
			display: block;
		}
		input {
			border: none;
			border-bottom: 1px solid #e3ebff;
			background: transparent;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			padding: 0;
			color: #526288;
			&::placeholder {
				color: #b6b7bb;
			}
		}
		input[type="submit"] {
			background: -webkit-linear-gradient(-103deg, #ef774c 0%, #ed684f 35%, #e84351 76%, #e73351 100%);
			font-weight: 500;
			text-transform: capitalize;
			color: #ffffff;
			border: none;
			-webkit-border-radius: 25px;
			-moz-border-radius: 25px;
			border-radius: 25px;
			height: 50px;
		}
		textarea {
			padding: 0;
			padding-top: 10px;
			border: none;
			border-bottom: 1px solid #e3ebff;
			background: transparent;
			color: #526288;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			height: 130px;
			&::placeholder {
				color: #b6b7bb;
			}
		}
	}
}

.notification-area.active {
	visibility: visible;
	opacity: 1;
}
.notify-overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: none;
	z-index: 9;
}
.notify-overlay.active {
	display: block;
}
.mt--85 {
	margin-top: -85px;
}
.progress-wrapper {
	padding: 20px 30px;
	background: #4a61ff;
	border-radius: 20px;
	margin-bottom: 50px;
	.title {
		margin: 0;
		margin-bottom: 13px;
	}
}
.m-0-15-20-none {
	margin: 0 -15px -20px;
}
.cl-white {
	color: #ffffff;
}
.earn-item.small-thumbs {
	border: 1px solid rgba(69, 94, 238, 0.3);
	.earn-thumb {
		width: 60px;
	}
	.earn-content {
		width: calc(100% - 60px);
		ul {
			li {
				max-width: 100%;
			}
		}
	}
}
.dashboard-bg {
	background: #fafbff;
}
.cl-4 {
	color: #23d605;
}
.mb--5 {
	margin-bottom: -5px;
}
.mb-30 {
	margin-bottom: 30px;
}
.pb-30 {
	padding-bottom: 30px;
}
.m-0-15-none {
	margin: 0 -15px -15px;
}
.operations {
	padding-top: 20px;
	padding-bottom: 80px;
	.main-title {
		margin-bottom: 10px;
		font-weight: 500;
		text-transform: capitalize;
	}
}
.deposit {
	padding-top: 20px;
	padding-bottom: 80px;
	.main-title {
		margin-bottom: 25px;
		font-weight: 500;
		text-transform: capitalize;
	}
	.main-subtitle {
		font-weight: 500;
		color: #526288;
		margin-bottom: 20px;
	}
}
.owl-next {
	line-height: 1;
	font-size: 45px;
	padding: 0;
	text-align: center;
	color: #aaa;
	&:hover {
		color: #fff;
	}
}
.owl-prev {
	line-height: 1 !important;
	font-size: 45px  !important;
	padding: 0  !important;
	text-align: center  !important;
	color: #aaa  !important;
	&:hover {
		color: #fff;
	}
}
.owl-next.active {
	color: #fff;
}
.owl-prev.active {
	color: #fff;
}
.owl-nav {
	height: 0;
	top: 50%;
	left: 0;
	width: 100%;
	position: absolute;
	display: flex;
	justify-content: space-between;
}
.click-number {
	text-align: center;
	width: 100px;
	line-height: 50px;
	border: 1px solid rgba(144, 155, 196, 0.5);
	border-radius: 20px;
	font-size: 24px;
	font-weight: 700;
	color: #37055c;
	margin-top: 10px;
}
.partners {
	padding-top: 20px;
	padding-bottom: 60px;
	.main-title {
		margin-bottom: 30px;
		font-weight: 500;
		text-transform: capitalize;
	}
}
.button-2 {
	padding: 15px 30px;
	line-height: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	border: 1px solid #bccaea;
	font-weight: 500;
	text-transform: capitalize;
	color: #526288;
	&:hover {
		color: #ffffff;
		background: -webkit-linear-gradient(-103deg, #ef774c 0%, #ed684f 35%, #e84351 76%, #e73351 100%);
		border-color: transparent;
	}
}
.refers {
	padding: 7px 15px;
	width: 100%;
}
.promotional {
	padding: 7px 15px;
}
.pagination-cont {
	padding: 10px 15px;
	font-size: 16px;
	color: #33406a;
}
.pagination-area {
	margin: 10px -15px -10px;
}
.promo-item {
	margin-bottom: 60px;
	.thumb {
		margin-bottom: 30px;
		img {
			max-width: 100%;
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.promo-link {
	border: 1px solid #c6cde7;
	background-color: #f6f7ff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	a {
		color: #00cca2;
		padding: 15px 30px;
		display: block;
		&:hover {
			text-decoration: underline;
		}
	}
}
.notification-check-area {
	padding: 40px 30px;
	border: 1px solid #b2b2b2;
	border-radius: 30px;
	.subtitle {
		font-weight: 500;
		margin-bottom: 30px;
	}
}
.create_wrapper {
	padding: 40px 30px;
	border: 1px solid #b2b2b2;
	border-radius: 30px;
	max-width: 540px;
	background: #fff;
	.subtitle {
		font-weight: 500;
		margin-bottom: 30px;
		text-transform: capitalize;
	}
	.sub_subtitle {
		font-weight: 500;
		font-size: 20px;
	}
}
.create_form_group {
	margin-bottom: 30px;
	label {
		font-weight: 700;
		color: #33406a;
		margin-bottom: 0;
	}
	input {
		background: transparent;
		border: none;
		border-bottom: 1px solid #bccaea;
		padding: 0;
		border-radius: 0;
		height: 50px;
	}
	textarea {
		background: transparent;
		border: none;
		border-bottom: 1px solid #bccaea;
		padding: 0;
		border-radius: 0;
		height: 140px;
		padding-top: 12px;
	}
	.custom-button {
		width: unset;
		min-width: 160px;
	}
	.select-item {
		height: 42px;
		.select-bar {
			background: transparent;
			border: none;
			border-bottom: 1px solid #bccaea;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			.current {
				padding: 0;
			}
		}
		.list {
			max-height: 200px;
			overflow-y: auto;
		}
	}
	.check_box_group {
		padding: 0;
		width: 100%;
		label {
			font-size: 16px;
			font-weight: 400;
		}
		input {
			height: 28px;
		}
	}
}
.update_user {
	width: 80px;
	height: 80px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.fz-sm {
	font-size: 16px !important;
}
.profile_update_input {
	display: none;
}
.h-40 {
	height: 40px !important;
}
.lh-40 {
	line-height: 40px !important;
}
.scrollToTop {
	width: 35px;
	height: 35px;
	line-height: 35px;
	color: #ffffff;
	z-index: 999;
	bottom: 30px;
	right: 30px;
	position: fixed;
	border-radius: 5px;
	-webkit-transform: translateY(150px);
	-ms-transform: translateY(150px);
	transform: translateY(150px);
	background: #777;
	text-align: center;
	font-size: 16px;
	img {
		width: 100%;
	}
}
.scrollToTop.active {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	animation: bounceInDown 2s;
	-webkit-animation: bounceInDown 2s;
	-moz-animation: bounceInDown 2s;
}
.custom-button {
	color: #ffffff;
	cursor: pointer;
	text-transform: uppercase;
	line-height: 43px;
	border: 1px solid transparent;
	background: rgb(85,14,137);
	background: linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
	padding: 0 30px;
	display: inline-block;
	font-weight: 600;
	font-size: 16px;
	-moz-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	-webkit-box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	box-shadow: 0 0 5px rgba(68, 68, 68, 0.6);
	i {
		-webkit-transition: all ease 0.3s;
		-moz-transition: all ease 0.3s;
		transition: all ease 0.3s;
		padding-left: 10px;
		display: inline-block;
	}
	&:hover {
		background: #fff;
		color: rgb(85,14,137) !important;
		border: 1px solid rgb(85,14,137);
		i {
			padding-left: 15px;
		}
	}
}
.custom-button.white {
	background: #ffffff;
	color: rgb(85,14,137) !important;
	border: 1px solid rgb(85,14,137);
	&:hover {
		background: rgb(85,14,137);
		background: linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
		background: -webkit-linear-gradient(0deg, rgba(85,14,137,1) 0%, rgba(60,7,100,1) 100%);
		color: #ffffff !important;
	}
}
.normal-button {
	background: #f8f9fa;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	border: 1px solid #bccaea;
	color: #33406a;
	line-height: 30px;
	padding: 10px 35px;
	i {
		margin-left: 7px;
	}
}
.main--body {
	overflow: hidden;
	min-height: 100vh;
}
.container {
	max-width: 1320px;
}
.top-social-icon {
	ul {
		li {
			a {
				padding: 0 3px;
				outline: none;
			}
		}
	}
}

.section-light-grey {
	background: #f5f5f5;
}
.card {
	background: #fff;
	border: none;
	padding: 20px;
	border-radius: 0;
}
.reading-block {
	.card {
		.title {
			padding-top: 10px;
			&:before {
				content: "";
				border-top: 5px solid #ddd;
				width: 50px;
				display: block;
				padding-bottom: 15px;
			}
		}
	}
}
.submit-date {
	font-size: 14px;
	color: #888;
}
.footer-col {
	padding-bottom: 20px;
}
section .owl-theme .owl-dots {
	text-align: center;
	padding-top: 35px;
	.owl-dot {
		width: 35px;
		height: 6px;
		border-radius: 0;
		display: inline-block;
		border: 1px solid #555;
		margin: 0 3px;
		background: #fff;
		box-shadow:0 0 0;
		&:focus {
			outline: none;
		}
		span{
			display:none !important
		}
	}
	.owl-dot.active {
		background-color: #37055c;
		border: 1px solid #37055c;
	}
}
.hero-section-main {
	.owl-dots {
		position: absolute;
		bottom: 120px;
		left: 0;
		right: 0;
	}
}
.hero-section-main {
	.owl-nav {
		button.owl-prev {
				background: #f0ecf5 !important;
				padding: 3px 10px !important;
				font-size: 34px !important;
				width: 40px;
				height: 53px;
				box-shadow: 0 0 0 !important;
				margin: 0;
				&:hover {
					background: #37055c;
				}
				span{
					position: relative;
					top: -2px;
				}	
		} 
		button.owl-next {
				background: #f0ecf5 !important;
				padding: 3px 10px !important;
				font-size: 34px !important;
				width: 40px;
				height: 53px;
				box-shadow: 0 0 0 !important;
				margin: 0;
				&:hover {
					background: #37055c;
				}
				span{
					position: relative;
					top: -2px;
				}	
		}
	}
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #f0ecf5;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline .timeline-child {
  padding: 10px 30px 10px 20px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline .timeline-child::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: #f0ecf5;
  border: 4px solid #2d0158;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.timeline .left {
  left: 0;
}

/* Place the container to the right */
.timeline .right {
  left: 50%;
  padding: 10px 20px 10px 30px;
}

/* Add arrows to the left container (pointing right) */
.timeline .left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 20px;
  border: medium solid #f0ecf5;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #f0ecf5;
}

/* Add arrows to the right container (pointing left) */
.timeline .right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 20px;
  border: medium solid #f0ecf5;
  border-width: 10px 10px 10px 0;
  border-color: transparent #f0ecf5 transparent transparent;
}

/* Fix the circle for containers on the right side */
.timeline .right::after {
  left: -12px;
}

/* The actual content */
.timeline .content {
  padding: 20px;
  background-color: #f0ecf5;
  position: relative;
  border-radius: 6px;
}

.fa-whatsapp {
	font-size: 17px;
}

.inner-page .header-bottom{
	background:#fff;
}
.react-datepicker-wrapper{
	width:100% !important;
}

.click-effect{
	margin-top: 15px;
}

.team-photo{
	text-align: center;
	margin-top: 15px;
}

.flip-card{
	perspective: 1000px;
}

.front-back-face{
	position: relative;
  	width: 100%;
  	height: 100%;
	transition: transform 1s;
  	transform-style: preserve-3d;
}

.flip-card:hover .front-back-face{
	transform: rotateY(180deg);
}

.front-face, .back-face{
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	top:	0;
	padding:25px;
	border-radius:10px;
}

.front-face{
	position:relative;
	border-radius: 10px;
	background-color: #885ecc;
	color:#fff;
	margin-bottom: 20px;	
}

.back-face{
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	background-color: #7d54bf;
	color:#fff;
}

.full-img{
	width:100%
}



.team-title{
	text-align: left;
	color: #fff;
	font-weight: normal;
	font-size: 26px;
	line-height: 32px;
}

.member-name{
	font-size: 20px;
	margin: 0 0 5px 0;
	font-weight: 500;
}

.title-designation{
	font-size: 14px;
	font-weight: normal;
}


.circle{
	border-radius: 50%;
	margin: 0 7px 0 0;
	background: #e5e5e5;
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	
	&:hover{
		background:#37055c;
		color:#fff;
	}
}


/*====model box =======*/

.ReactModal__Overlay{
	background-color: rgba(0, 0, 0, 0.75) !important;
	z-index: 11;
}

.ReactModal__Content{
	border:none !important;
	border-radius: 10px !important;
	overflow: hidden !important;
}

.modal-content{
	border:none;
}

.modal-custom-content{
	overflow: auto;
	height: 270px;
	padding-right: 25px;
}

.modal-header{
	padding:0;
	border: none;
}

.modal-body{
	padding:0
}

.team-popup-img img{
	width:100%
}

.modal-body .member-name{
	color: #37055c;
	padding-right: 40px;
	padding-top: 10px;
}

.close i{
	color:#37055c;
	transform: rotate(45deg);
	font-size: 22px;
}

.modal-content a.close{
	position: absolute;
	right: -15px;
	top: -20px;
	padding: 10px;
	cursor: pointer;
	z-index: 1;
	padding: 0;
	opacity: 1 !important;
}


/*===end =model box =======*/


.product-list-card{
	
	
	.card{
		max-width: 300px;
		margin: auto;
		text-align: left;
		background-color: #e4e8e8;
		color: #080808;
	}

	span#exampleModalLongTitle {
		color: #7a7a7a;
		font-size: 12px;
	}

	.view-more{
		cursor: pointer;
		text-transform: uppercase;
	}

	
}

.product-detail{
	text-align: left;
	text-align: justify;

	.product-image{
		margin-bottom: 15px;
		width: 100%;
	}

	.team-image{
		padding: 0px 20px 0px 0;
	}

	.view-pdf{
		padding: 0px 0px 10px 0px;
	}

	iframe {
		width: 100%;
	}
}

.insight-pagination{
	.pagination li a{
		background-color: #37055c;
		color: #ffffff;
		line-height: 20px;
	}

	.pagination li a:hover{
		background-color: #37055c;
		background: #37055c;
		color: #ffffff;
	}
}

.client-on-boarding{
	input#mode_of_investment{
		width: auto;
		box-shadow: none;
		height: 20px;
	}
	input#mode_of_infusion{
		width: auto;
		box-shadow: none;
		height: 20px;
	}
	input#mode_of_redemption{
		width: auto;
		box-shadow: none;
		height: 20px;
	}
	input#mode_of_capital_infusion{
		width: auto;
		box-shadow: none;
		height: 20px;
	}

	.form-check {
		padding: 0px 30px 0px 20px;
	}

	.client-category {
		background: whitesmoke;
	}

	input#radios1 {
		// height: 20px;
		// margin-left: 35px;
		width: auto;
		box-shadow: none;
		height: 20px;
	}

	input#radios2 {
		// height: 20px;
		// margin-left: 50px;
		width: auto;
		box-shadow: none;
		height: 20px;
	}

	.financial-radio{
		width: auto;
		box-shadow: none;
		height: 20px;
	}

	input.exposed_person {
		height: 20px;
		margin-left: 95px;
	}

	.background-white{
		background: white;
	}
}

.checkbox-left-allign{
	width: auto;
    box-shadow: none;
	height: 20px;
}

.radio-left-align{
	width: auto;
    box-shadow: none;
    height: 20px;
}

.holder {
    background-color: #37055c;
    color: white;
    padding: 5px;
}

@media print {
	* {
		background: transparent !important;
		color: #000 !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		text-shadow: none !important;
		&:before {
			background: transparent !important;
			color: #000 !important;
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
		&:after {
			background: transparent !important;
			color: #000 !important;
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
	}
	a {
		text-decoration: underline;
		&:visited {
			text-decoration: underline;
		}
	}
	a[href] {
		&:after {
			content: " (" attr(href) ")";
		}
	}
	abbr[title] {
		&:after {
			content: " (" attr(title) ")";
		}
	}
	a[href^="#"] {
		&:after {
			content: "";
		}
	}
	a[href^="javascript:"] {
		&:after {
			content: "";
		}
	}
	pre {
		white-space: pre-wrap !important;
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr {
		page-break-inside: avoid;
	}
	img {
		page-break-inside: avoid;
	}
	p {
		orphans: 3;
		widows: 3;
	}
	h2 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}
	h3 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}
}
@media (min-width: 576px) {
	h1 {
		font-size: 80px;
	}
	h2 {
		font-size: 36px;
		line-height: 50px;
	}
	h3 {
		font-size: 30px;
		line-height: 42px;
	}
	h4 {
		font-size: 26px;
		line-height: 34px;
	}
	h5 {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 10px;
	}
	h6 {
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 10px;
		font-weight: 500;
	}
	.section-header.left-style {
		text-align: left;
		margin-bottom: 60px;
	}
	.section-header {
		margin-bottom: 40px;
	}
	.banner-content {
		.button-group {
			.custom-button {
				margin-right: 65px;
			}
		}
	}
	
	.about--content {
		margin-bottom: -46px;
	}
	
}

@media (min-width: 992px) {
	.padding-top {
		padding-top: 60px;
	}
	.padding-bottom {
		padding-bottom: 60px;
	}
	.mw-lg-100 {
		min-width: 100%;
	}
	.footer-section {
		&::before {
			top: 200px;
		}
	}
	.header-section.plan-header {
		.header-bottom {
			.header-area {
				.menu {
					li {
						>a {
							color: #ffffff;
						}
					}
				}
			}
		}
	}
	.header-bottom {
		.header-area {
			.menu {
				li {
					padding: 10px 0;
					margin: 0 10px;
					a {
						padding: 10px 0;
						font-size: 14px;
						font-weight: 600;
						text-transform: uppercase;
						color: #222;
						outline: none;
						position: relative;
						&:hover {
							color: #37055c;
						}
					}
					a.custom-button {
						padding: 0 10px;
						color: #ffffff;
					}
					.submenu {
						position: absolute;
						background: #ffffff;
						top: 100%;
						left: 0;
						z-index: 99;
						width: 200px;
						-webkit-transform: translateY(10px);
						-ms-transform: translateY(10px);
						transform: translateY(10px);
						-webkit-transition: all ease 0.3s;
						-moz-transition: all ease 0.3s;
						transition: all ease 0.3s;
						visibility: hidden;
						opacity: 0;
						box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
						-moz-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
						-webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
						li {
							padding: 0;
							margin:0;
							a {
								padding: 7px 15px;
								display: flex;
								align-items: center;
								border-top: 1px solid #ddd;
								font-weight: 500;
								font-size:14px;
								text-transform: capitalize;
								justify-content: space-between;
								&:hover {
									background: #37055c;
									color: #ffffff;
								}
							}
							&:first-child {
								a {
									border-top:none
								}
							}
							.submenu {
								top: 0;
								left: 100%;
							}
						}
					}
					&:hover {
						>.submenu {
							-webkit-transform: translateY(0);
							-ms-transform: translateY(0);
							transform: translateY(0);
							visibility: visible;
							opacity: 1;
						}
					}
				}
				li.nav-link.active {
					a {
						/*color: #37055c;*/
						&:before {
							/*background: #37055c;
							width: 100%;*/
						}
					}
				}
				li.nav-link {
					> a {
						&:before {
							content: "";
							width: 0px;
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0px;
							height: 2px;
							margin: 0 auto;
							transition: ease 0.5s;
						}
						&:hover {
							&::before {
								background: #37055c;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	
}

@media (max-width: 575px) {
	.section-header {
		.cate {
			font-size: 18px;
			margin-top: -9px;
			margin-bottom: 16px;
		}
		
	}
	h2.title {
		margin-bottom: 20px;
		font-size: 24px;
	}
	.footer-top {
		.links {
			li {
				a {
					font-weight: 500;
				}
			}
		}
	}
	.banner-section {
		padding: 312px 0 180px;
	}
	.banner-content {
		.title {
			font-size: 38px;
			line-height: 48px;
		}
		p {
			font-size: 20px;
			line-height: 30px;
		}
		.button-group {
			margin: -10px;
			a {
				margin: 10px !important;
				&::after {
					display: none;
				}
				&::before {
					display: none;
				}
			}
			a.custom-button {
				font-size: 14px;
			}
		}
	}
	
	.hover-tab-menu {
		.tab-menu {
			li {
				margin-bottom: 40px;
				.menu-thumb {
					width: 50px;
					height: 50px;
					span {
						width: 38px;
						height: 38px;
						text-align: center;
						line-height: 38px;
						font-size: 24px;
					}
				}
				.menu-content {
					padding-left: 15px;
					width: calc(100% - 50px);
					.title {
						margin-bottom: 10px;
						font-size: 18px;
						font-weight: 500;
					}
				}
			}
		}
	}
	
	.hero-section-2 {
		padding-top: 248px;
	}
	.hero-content {
		.title {
			font-size: 40px;
			margin-bottom: 0;
			line-height: 44px;
		}
	}
	.header-top {
		ul {
			li {
				padding: 0 2px;
				a{
					padding: 3px 5px;
					i{
						margin-right: 4px;
					}
				}
			}
		}
	}
	.header-bottom {
		.header-area {
			.logo {
				a {
					img {
						max-width: 62%;
					}
				}
			}
		}
	}
	
}
@media (max-width: 991px) {
	
	.header-bottom {
		.header-area {
			padding: 15px 0;
			.menu {
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				max-height: calc(100vh - 130px);
				z-index: 9;
				background-color: #ffffff;
				overflow-y: auto;
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
				padding: 20px 0;
				-webkit-transition: all ease 0.3s;
				-moz-transition: all ease 0.3s;
				transition: all ease 0.3s;
				-webkit-transform: scaleY(0);
				-ms-transform: scaleY(0);
				transform: scaleY(0);
				transform-origin: top;
				li {
					width: 100%;
					padding: 0;
					a {
						padding: 10px;
						display: flex;
						align-items: center;
						border-top: 1px solid rgba(51, 64, 106, 0.4);
						font-weight: 500;
						text-transform: capitalize;
						justify-content: space-between;
						color: #222;
						&:hover {
							color: #37055c;
						}
					}
					a.custom-button {
						display: block;
						padding: 0 50px;
						color: #ffffff;
						border-top: none;
						text-align: center;
					}
					.submenu {
						display: none;
						padding-left: 20px;
						li {
							a {
								font-size: 15px;
							}
						}
					}
					.active-submenu{
						display:block
					}
				}
			}
			.menu.active {
				-webkit-transform: scaleY(1);
				-ms-transform: scaleY(1);
				transform: scaleY(1);
			}
		}
		.container-fluid {
			padding: 0;
		}
	}
	.logo {
		padding-left: 10px;
	}
	.header-bar {
		right: 15px;
	}
	.banner-section {
		padding: 250px 0 180px;
	}
	.banner-content {
		.title {
			letter-spacing: 0;
			margin-bottom: 20px;
		}
	}
	.background-map {
		display: none;
	}
	
}
@media (max-width: 767px) {
	.pt-max-md-0 {
		padding-top: 0;
	}
	.pb-max-md-0 {
		padding-bottom: 0;
		padding-bottom: 0;
	}
	.banner-content {
		.title {
			font-size: 50px;
			line-height: 60px;
		}
	}
	
	.hero-section {
		padding: 70px 0 150px;
	}
	.hero-section-2 {
		padding-top: 222px;
		background: #4a61ff !important;
	}
	.hero-content {
		.title {
			font-size: 34px;
		}
	}
	.faq-item {
		.faq-content {
			padding-left: 40px;
		}
		.faq-title {
			padding-left: 40px;
			&::before {
				width: 30px;
			}
			.title {
				font-size: 22px;
			}
		}
	}
	.tot-wrapper {
		.tot-area {
			width: 100%;
			max-width: 255px;
		}
	}
	.contact-wrapper {
		padding: 70px 30px 0;
	}
	.contact-form {
		.form-group {
			label {
				font-size: 18px;
				margin-bottom: -5px;
			}
		}
	}
	.total-earning-item {
		.item {
			.month {
				font-size: 14px;
			}
		}
	}
	.deposit-wrapper {
		.deposit-item {
			width: 100%;
		}
	}
	.check_box_group {
		padding-bottom: 30px;
	}
}



@media (min-width: 992px) and (max-width: 1249px) {
	.header-bottom {
		.header-area {
			.menu {
				li {
					padding: 5px 5px;
					margin:0;
					a {
						font-size: 9px;
					}
				}
			}
		}
	}

	
	.header-bottom  {
		.header-area {
			.logo{
				width: 175px;
				a {
					img{
						max-width: 75%;
    					padding-top: 0px;
					}
				}

			
			}
		}
	}





	.hover-tab-menu {
		.tab-menu {
			li {
				&:nth-child(2) {
					-webkit-transform: translateX(75px);
					-ms-transform: translateX(75px);
					transform: translateX(75px);
					.menu-content {
						max-width: 320px;
					}
				}
			}
		}
	}
}


@media (min-width: 1166px) and (max-width: 1426px) {
	.header-bottom {
		.header-area {
			.menu {
				li {
					padding: 5px 5px;
					margin:0;
					a {
						font-size: 11px;
					}
				}
			}
		}
	}
}


@media (min-width: 980px) and (max-width: 1020px) {
	.header-bottom  {
		.header-area {
			.logo{
				width: 155px;
				a {
					img{
						max-width: 70%;
    					padding-top: 0px;
					}
				}

			
			}
		}
	}
}	

@media screen and (max-width:991px) {
	.inner-page{
		.hero-section{
			padding: 50px 0 190px;
			.hero-content{
				padding-left: 0;
			}
		}
	}
}


button:focus{
	outline:none
}

.container-inner{
	max-width: 1150px;
}

.modal-content .bg-shape{
	margin:-20px;
}

.bg-shape{
	background-image:url('/assets/images/popup-bg.png');
}

.newsletter-icon{
	position: absolute;
	left: 0;
	top: 175px;
}

.box-shadow{
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.box-block{
	border-radius: 15px;
	-moz-border-radius: 15px;
	-webkit-border-radius: 15px;
}

.small-text{
	font-size:14px;
	line-height: 20px;
}

.input-group-text{
	background-color: #37055c;
	border: 1px solid #37055c;
	color: #fff;
}

.custom-control-label::before{
	top: 1px;
	border: #666 solid 1px;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus{
	box-shadow: 0 0 0 .2rem rgba(55,5,92,.8);
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle{
	background-color: #37055c;
	border-color: #37055c;
}

a u:hover{
	text-decoration:none
}

.custom-select{
	border-radius: 0;
	font-size: 15px;
	color: #222;
	padding: 11px;
	height: auto;
	padding-left: 6px;
	font-weight: 400;
	border: 1px solid #37055c;
}

.btn-sm{ 
	font-size: 14px;
	padding: 0 17px;
	line-height: 40px;
	border-radius: 0;
}

.shide{
	display:none;
}
.bshow{
	display : block
}

.custom-control-input:checked ~ .custom-control-label::before{
	border-color: #37055c;
	background-color: #37055c;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
	top: 1px;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
	background-color: #37055c;
}

.otp-block input.form-control{
	width: 55px;
	text-align: center;
	height: 55px;
	margin: 0 5px;
	font-size: 30px;
	padding:5px !important;
	font-weight: 500;
	background-position: center center;
}

.icon-green{
	color:#5cb85c
}

.otp-block .invalid-feedback{
	display:none !important
}

.otp-block input.is-valid{
	background: none !important;
	padding: 5px !important;
}

.alert button.close{
	width: 22px;
	height: 22px;
	padding: 5px;
	line-height: 23px;
	background: #000;
	border-radius: 50px;
	line-height: 11px;
	position: absolute;
	right: 10px;
	top: 10px;
	outline:none;
}

.alert button.close:hover,
.alert button.close:focus{
	background:#fff;
}

.custom-message > div > div > div > div{
	right: 0;
	left: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	display: flex;
	align-items: center;
}

.custom-message .alert{
	margin: 0 auto;
	max-width: 500px;
	width: 90%;
	text-align: center;
	font-size: 18px;
	padding: 50px 20px;
}

.custom-message .alert i{
	font-size: 34px;
	padding:0 0 20px 0;
	display: block;
}

.sidebar{
	background:#d6c9e1;
	border-radius: 15px 0 0 15px;
}

.sidemenu{
	padding:30px 0 !important;
	height: 100%;
}

.status-select-bar{
	background: #eaeaea;
	padding: 13px;
	margin: 20px 0;
}

.status-select-bar .form-group{ 
	margin:0
}

.status-select-bar .form-group select{
	width: 220px;
}

.sidemenu{
	li{
		padding:2px 0;
		list-style: none !important;
		a{
			padding: 10px 15px;
			display: block;
			color: #000;
			font-size: 16px;
			font-weight: 400;
			border-left: 3px solid transparent;
			i{
				width: 20px;
			}
			
		}
		&:hover a{
			background: #fff;
			border-left: 3px solid #37055c;
			color: #37055c;
		}
		.active{
			background: #fff;
			border-left: 3px solid #37055c;
			color: #37055c;
		}
		
	}
}

.vallum-tracker{
	margin-bottom: 30px;
}

.status-number{
	padding: 0 15px;
}

.tracker-list {
	padding: 10px 0;
	min-height: 110px;
	position: relative;
	.steps-step{
		width: 60px;
		height: 60px;
		border: 2px solid #d6c9e1;
		border-radius: 100px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		font-size: 18px;
		color: #37055c;
		font-weight: 500;
		z-index: 1;
		position: relative;
		background: #fff;
	}
}

.tracker-list.active-tracker .status-number .steps-step{
	background:#540d89;
	color:#fff;
	border: 2px solid #540d89;
}

.tracker-list:after{
	top: 3rem;
	bottom: 0;
	position: absolute;
	content: "";
	width: 2px;
	height: 100%;
	background-color: #d6c9e1;
	left: 30.5%;
	margin: auto;
}

.tracker-list:last-child::after{
	content:inherit
}

.tracker-list.active-tracker .status-number:before{
	background-color: #540d89;
}

.status-description a{
	font-weight: 500;
	text-decoration: underline;
}

.footerfixedPart{
	text-align: center;
	position: fixed;
	bottom: 0;
	background: #fff;
	width: 100%;
	padding: 8px;
	z-index: 1;
	border-top: 1px solid #ccc;
	box-shadow: 0px 0px 5px #ddd;
	font-weight:500;
	font-size:13px;
	line-height: 20px;
}

.fa-info-circle{
	font-size:18px; 
	font-style:italic;
}

.custom-file{
	margin-top: 10px
}

.custom-profile-pic label.custom-file-label:after{
	content: inherit;
}

.custom-file-input{
	cursor:pointer;
}

.custom-profile-pic label.custom-file-label{
	text-align: center;
	background: #37055c;
	color: #fff;
	border: none;	
}

.clear-both{
	clear:both
}

.title{
	margin-bottom: 10px;
}

.box-grey{
	background:#eee
}

.white-text{
	color:#fff;
}

.timeline-card-content{
	background:#e1d8ec !important;
}

.timeline-main-wrapper > .horizontal > div{
	background: #fff;
}

.timeline-circle:not(.using-icon) {
    background: #fff !important;
}

.timeline-circle:not(.using-icon).active::after {
    background: #37055c !important;
}

#timeline-main-wrapper{
	height:8rem;
}

.timeline-item-title{
	border-radius: 5px !important;
	-moz-border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
	width: 90px;
	height: 40px;
	text-align: center !important;
	font-size: 0.9rem !important;
	line-height: 33px;
	font-weight: 500 !important;
	background:#fff !important;
	color:#37055c !important;
	box-shadow: 0px 0px 35px rgba(255, 255, 255, 0.8);
	-moz-box-shadow: 0px 0px 35px rgba(255, 255, 255, 0.8);
	-webkit-box-shadow: 0px 0px 35px rgba(255, 255, 255, 0.8);
}

.timeline-item-title.active{
	background:#4c0b7c !important;
	color:#fff !important
}

#timeline-main-wrapper ul.horizontal .css-wabwpe-Wrapper .horizontal{
	bottom: 2.5em;
}

.timeline-content-block{
	margin-top: 0;
	padding:0 20px;
}

.timeline-content-block > .horizontal{
	padding: 0 25px;
}

.timeline-content-block .custom-button{
	margin-bottom:20px;
}

.timeline-controls li button{
	background: #370161;
}

.information-request-section .needs-validation textarea{
	height: 135px;
}

.react-datepicker__header{
	background-color: #e1d8ec !important;	
}

.timeline-card-content .card-description{
	margin-top: 20px;
	max-height: inherit !important;
}

.timeline-card-content .show-more{
	display:none
}

.timeline-controls{
	background:none !important
}

.timeline-controls li:nth-child(1),
.timeline-controls li:nth-child(4),
.timeline-controls li:nth-child(5){
	display:none
}

.timeline-controls li button{
	color:#fff !important
}

.react-datepicker{
	border:1px solid #37055c !important;
}

.react-datepicker button.react-datepicker__navigation{
	box-shadow:0 0 0 !important;
	-moz-box-shadow:0 0 0 !important;
	-webkit-box-shadow:0 0 0 !important;
}

.react-datepicker button.react-datepicker__navigation:hover{
	background:none !important
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #37055c !important;
}

.react-datepicker__navigation--previous, .react-datepicker__navigation--previous:hover{
	border-right: 8px solid #37055C !important;
	border-left: 8px solid transparent !important;
	border-top: 8px solid transparent !important;
	border-bottom: 8px solid transparent !important;
}

.react-datepicker__navigation--next, .react-datepicker__navigation--next:hover{
	border-left: 8px solid #37055C !important;
	border-right: 8px solid transparent !important;
	border-top: 8px solid transparent !important;
	border-bottom: 8px solid transparent !important;
}

.relative{
	position:relative
}

.overlay-bg {
    position: relative;
    overflow: hidden;
}

.overlay-bg:before{
	top: 0;
	left: 0;
	right:0;
	bottom:0;
	position: absolute;
	content: "";
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
}

.grid-item-content {
    /*min-height: 160px;*/
}

.grid-item-short{
	/*min-height:135px;*/
}

.full-image img, .full-image iframe{
	width:100%
}

.table td, .table th{
	padding: .5rem;
	
}

.table thead th{
	font-weight: 600;
}

.custom-file-input{
	height: calc(2em + .75rem + 2px);
}

.custom-file-label{
	border: 1px solid #37055c;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	height: calc(2em + .75rem + 2px);
	line-height: 2;
}

.custom-file-label::after{
	height: calc(2em + .75rem);
	line-height: 2;
	background-color: #37055c;
	color: #fff;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
}

.form-control.is-valid, .was-validated .form-control:valid{
	background-size: calc(.5em + .375rem) calc(.75em + .375rem);
	background-position: right calc(1.1em + .1875rem) center;
}

.height-full{
	height:100%
}

.contact-info{
	font-size: 16px;
	line-height: 26px;
}

.profile-section{
	padding-top: 100px;
}

@media(max-width:1024px){

	.sidemenu li a{
		font-size: 15px;
	}

}

@media(min-width:767px) and (max-width:991px){

	.tracker-list::after{
		left: 33%;
	}
	
	.media-video{
		height: 220px;
	}
}

@media(max-width:991px){
	.tracker-list .steps-step { 
		width: 50px;
		height: 50px;
	}

	.table-responsive{
		margin-bottom: 25px;
	}
	
	.hero-content .title{
		font-size: 30px;
    	line-height: 36px;
	}
	
	.newsletter-icon{
		display: none;
	}
	
	.hover-tab-menu::after{
		content:inherit;
		display:none;
	}
	
	.hover-tab-menu::before{
		content:inherit;
		display:none;
	}
	
	.hover-tab-menu .tab-menu li{
		margin-bottom: 20px;
	}
	
	.hover-tab-menu .tab-menu li .menu-content .title{
		margin-bottom: 5px;
	}
	
}

@media(max-width:870px){
	.hero-content {
		
	}
}

@media(max-width:767px){
	.tracker-list .steps-step {
		width: 40px;
		height: 40px; 
	} 

	.footerfixedPart{
		font-size:12px
	}

	.timeline-content-block{
		padding:0;
	}
	
	.timeline-content-block > .horizontal{
		padding: 0;
	}

	.team-popup-img img{
		width: 140px;
	}

	.hero-section {
		padding: 120px 0 200px;
	}

	.hover-tab-menu .tab-menu li {
		margin-bottom: 20px;
	}

	.timeline .content{
		padding: 15px;
	}

	.back-face .description{
		font-size:14px;
		line-height: 21px;
	}

	.front-face, .back-face{
		padding:20px;
	}

	.team-title{
		font-size: 20px;
		line-height: 24px;
	}

	.tracker-list::after{
		
	}

	.vallum-tracker {
		margin-bottom: 30px;
		font-size: 14px;
		line-height: 21px;
	}
	
	.media-video{
		height: 200px;
	}
	
	.sidemenu{
		border-radius: 15px;
		margin: 15px;
	}

	.hero-content .title {
	    font-size: 20px;
	    line-height: 22px;
	}
	
	.hover-tab-menu .tab-menu li:nth-child(2){
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
		-moz-transform: translateX(0px);
	}
} 

@media only screen and (max-device-width:767px) and (orientation: landscape)  {
    .media-video{
		height: 250px;
	}
}


@media(max-width:590px){

	.tracker-list::after{
		left: 32%;
	}

}

@media(max-width:480px){
	.timeline-card-content .card-description{
		width: 70%;
	}

	.front-face, .back-face{
		min-height: 430px;
	}

	.tracker-list::after{
		left: 33%;
	}
}

@media(max-width:400px){
	.tracker-list::after{
		left: 34.5%;
	}
}

.grid-item-content h5{
	min-height: 53px;
}

.box-block ul{
	padding-left: 20px;
}

.box-block ul li{
	list-style: disc;
}

.oneTimePopup h5{
	font-weight:600;
}

.oneTimePopup table tr th{
	font-weight:600;
	text-align:left;
}

.oneTimePopup table tr td{
	text-align:center
}

.oneTimePopup table tr th,
.oneTimePopup table tr td{
	padding:5px 10px;
	border:1px solid #555;
}